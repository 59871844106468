<template>
  <main class="stages-wrapper" :class="{ isInactive: !showStages }">
    <p
      class="tooltip -xstb"
      :style="`color: ${getTooltipColor}`"
      v-show="tooltip?.value"
    >
      {{ getTooltipValue }}
    </p>

    <section
      v-for="{
        category_id,
        category_name,
        stages: propStages,
      } in getFullStages"
      :key="category_id"
      class="category-wrapper"
    >
      <div :class="[sidenavOpened ? 'icon-wrapper' : 'icon-wrapper-toggled']">
        <img
          :class="{ toggledIcons: !sidenavOpened }"
          :src="getIcon(category_name)"
        />
      </div>

      <div
        v-for="(stage, index) in propStages"
        :key="stage"
        class="stage-container"
        :class="[
          { active: getSelectedStage(stage.frame_stage_id) },
          { hidden: !sidenavOpened },
        ]"
        :style="{
          color: getSelectedStage(stage.frame_stage_id) ? stage.color_code : '',
        }"
        :ref="`s${index}`"
        @click="setSelectedStages(stage.frame_stage_id, `s${index}`)"
        @mouseover="setTooltip(stage.stage_name, stage.color_code)"
        @mouseleave="resetTooltip"
      >
        <p class="-ntb stage" :ref="`p${index}`">{{ stage.stage_value }}</p>
      </div>

      <section class="bar-wrapper">
        <div
          v-for="stage in propStages"
          :key="stage"
          class="bar"
          :style="{
            background: getSelectedStage(stage.frame_stage_id)
              ? stage.color_code
              : '',
          }"
        ></div>
      </section>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      hideOnRoutes: [
        "Dashboard",
        "Lifecycle",
        "Elements",
        "Overview",
        "MSight",
      ],
      showStages: false,
      selectedStages: [],
      tooltip: { value: "", color: "" },
    };
  },
  created() {
    this.selectedStages = this.$store.state.selectedStages;
  },
  computed: {
    ...mapGetters([
      "stages",
      "getFullStages",
      "currentStageId",
      "sidenavOpened",
    ]),
    getTooltipValue() {
      return this.tooltip.value;
    },
    getTooltipColor() {
      return this.tooltip.color;
    },
  },
  methods: {
    getSelectedStage(frame_stage_id) {
      return this.selectedStages.includes(frame_stage_id) ? true : false;
    },
    setSelectedStages(value) {
      // this.$store.dispatch("updateSelectedStages", value); //ver depois

      if (!this.selectedStages.includes(value)) {
        this.selectedStages.push(value);
        this.selectedStages.sort();
      } else {
        if (this.selectedStages.length === 1) return;
        this.selectedStages = this.selectedStages.filter(
          (val) => val !== value
        );
      }
    },
    getIcon(icon_name) {
      const icon = icon_name.toLowerCase().replace(/\s/g, "-");

      return require(`@/assets/icons/sidenav/stages/${icon}.svg`);
    },
    setTooltip(value, color) {
      this.tooltip.color = color;
      this.tooltip.value = value.toString();
    },
    resetTooltip() {
      this.tooltip.color = "";
      this.tooltip.value = "";
    },
  },

  watch: {
    $route: {
      handler: function () {
        const excludedRoute = this.hideOnRoutes.find((route) => {
          return route === this.$route.name;
        });

        if (this.$route.name === excludedRoute) {
          if (!this.showStages) return;
          this.showStages = !this.showStages;
          // console.log("ran false");
        } else {
          if (this.showStages) return;
          this.showStages = !this.showStages;
          // console.log("ran true");
        }
      },
      immediate: true,
    },

    selectedStages: {
      handler: function (newVal, oldVal) {
        //this will need to be optimized in the future with an updateSelectedStages action on the store
        this.$store.dispatch("selectedStages", this.selectedStages.sort());
        console.warn("FROM WATCHER ON STAGESMENU: ", this.selectedStages);
      },
      deep: true,
    },

    "$store.state.currentStageId": function (newVal) {
      //when the currentStageID updates, reset the selectedStages array to new value and discard the rest

      this.selectedStages = [];
      this.selectedStages.push(this.$store.state.currentStageId);

      console.warn(
        "NEW VAL(FRAME_STAGE_ID) ON STAGES MENU: ",
        this.selectedStages
      );
    },
  },
};
</script>

<style scoped>
.stages-wrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  --stage-gap: 4px;
  overflow: hidden;
  --transition-effect: opacity 400ms ease-in-out;
  transition: var(--transition-effect);
}
.category-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--stage-gap);
  background: #bbc4da;
  padding-block: var(--stage-gap);
}

.category-wrapper:nth-child(2) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.category-wrapper:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

img {
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  transition: 200ms;
}
.stage-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding-block: 2px;
  cursor: pointer;
  color: #7d89b1;
}

.stage-container:hover {
  background: rgba(255, 255, 255, 0.2);
}
/* .stage-container:active {
  scale: 0.92;
} */

.active {
  background: #5d6c90;
  box-shadow: inset 0 1px 8px 1px var(--primary);
  /* border-bottom: 4px solid; */
}
.active:hover {
  background: #5d6c90;
}
.stage {
  align-self: center;
  transition: transform 250ms ease;
}
.tooltip {
  position: relative;
  z-index: -1;
  background: rgb(187, 196, 218);
  color: var(--primary-transp) !important;
  /* color: #c5cddf; */
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 4px;
  align-self: flex-start;
  margin-bottom: 4px;
  animation: slide-hleft-text 400ms ease backwards;
}
.bar-wrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}
.bar {
  width: 5px;
  height: 100%;
  border-radius: 4px;
  background: #1522435d;
}
.icon-wrapper-toggled {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 4px;
}

.isInactive {
  opacity: 0.2;
  pointer-events: none;
  user-select: none;
  transition: var(--transition-effect);
}

@media screen and (max-width: 1023px) {
  .tooltip {
    top: 110px;
  }
}
@media screen and (max-width: 719px) {
  .stage-container {
    width: 40px;
  }

  .stages-wrapper {
    display: none;
  }
}
</style>
