export default {
  install: (app) => {
    app.config.globalProperties.$getRemainingChars = (string, maxLength) => {
      return getRemainingChars(string, maxLength);
    };
  },
};

function getRemainingChars(string, maxLength) {
  return maxLength - string.length;
}
