<template>
  <main class="icon">
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4414 6L7.44141 11L2.44141 6"
        :stroke="strokeColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {
  props: ["strokeColor"], //always a string
};
</script>
