<template>
  <main class="date-container" v-if="isValid">
    <div class="box">
      <div class="box-inside">
        <div id="clock">
          <p class="-xltb">
            {{ time }} <span class="clock-span">{{ session }}</span>
          </p>
        </div>
        <p class="-xstr date">{{ date }}</p>
      </div>
      <div class="weather" :title="description">
        <span class="weather-img" :class="iconClass" id="iconWeather"
          ><img class="-xstb" :src="icon" alt="loading..." loading="lazy"
        /></span>
        <span class="weather-span">{{ temperature }}C</span>
      </div>
    </div>
  </main>
</template>

<script>
import getWeather from "../../services/requests/get/getWeather";

export default {
  data() {
    return {
      myDate: Object,
      time: "",
      session: "",
      date: "",
      offset: 1,
      temperature: "",
      description: "",
      iconClass: "",
      icon: "",
      clock: null,
      isToggled: false,

      //temp
      isValid: true,
    };
  },
  async created() {
    const city = "Porto";
    const country = "PT";
    const location = `${city}-${country}`;

    const res = await getWeather(location);
    // console.warn("PROEJCT DATE RES: ", res);

    if (res.status.code === 500) {
      this.isValid = false;
      return;
    }

    this.temperature = res.data.temperature + "º";
    this.description = res.data.description;
    this.icon = res.data.icon;
    console.log("CLOCK: ", res.data);
  },
  mounted() {
    this.currentTime();

    this.clock = setInterval(() => {
      this.currentTime();
    }, 60000);
  },
  //important - don't forget to clear the interval or you will overload the main thread eventually
  beforeUnmount() {
    clearInterval(this.clock);
  },

  methods: {
    currentTime() {
      let clock = new Date();
      let utc = clock.getTime() + clock.getTimezoneOffset() * 60000;
      let date = new Date(utc + 3600000 * this.offset);
      let hour = date.getHours();
      let minutes = date.getMinutes();
      const session = () => {
        if (!this.isToggled) return "";
        return hour < 12 ? "AM" : "PM";
      };

      hour = hour < 10 ? "0" + hour : hour;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      const time = hour + ":" + minutes;

      this.time = time;
      this.session = session();

      const dateToArray = date.toString().split(" ");

      const myDate = {
        day: dateToArray[0],
        month: dateToArray[1],
        dayOfMonth: dateToArray[2],
        year: dateToArray[3],
      };

      // this.date = `${myDate.day}. ${myDate.month} ${myDate.dayOfMonth}`;
      this.date = `${myDate.month} ${myDate.dayOfMonth} \u2219 ${myDate.year}`;
      console.log("clock ran");
    },
  },
};
</script>

<style scoped>
.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
  cursor: default;
  animation: slide-in-text 200ms ease-in-out;
}

.box {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* scale: 0.9; */
}

.box-inside {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: center;
}

.weather {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.8rem;
  padding-block: 4px;
  background: rgba(255, 255, 255, 0.2);
  background: radial-gradient(
    ellipse,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 90%
  );
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px 1px;
  border-radius: 1rem;
}

#clock > p {
  color: #e2eef8;
  font-family: "Montserrat-Regular";
  font-size: 1.4rem;
}

.clock-span {
  font-family: inherit;
  font-size: 1rem;
  padding-top: 4px;
}

.weather-span {
  font-family: "Montserrat-Regular";
  font-size: 0.75rem;
}

.weather-img {
  display: flex;
}

.date {
  margin-top: -8px;
  color: #87afe4;
}

hr {
  width: 100%;
  border: 1px solid white;
}

#iconWeather img {
  width: 24px;
  height: 24px;
  scale: 1.2;
}
</style>
