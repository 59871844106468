<template>
  <main class="loading-container" v-show="loading">
    <section class="logo-container">
      <div class="logo-m">
        <svg x="0px" y="0px" viewBox="0 0 664 566">
          <path
            fill="none"
            d="M428.621 478.617L271.589 60.2939C258.929 26.5687 211.224 26.5687 198.564 60.2938L41.5322 478.617C31.9605 504.116 50.8088 531.323 78.0444 531.323H392.109C419.344 531.323 438.193 504.116 428.621 478.617ZM280.951 56.7795C265.045 14.4069 205.108 14.4068 189.202 56.7794L32.1701 475.103C20.1441 507.139 43.8253 541.323 78.0444 541.323H392.109C426.328 541.323 450.009 507.139 437.983 475.103L280.951 56.7795Z"
          />
          <path
            fill="none"
            d="M622.621 478.617L465.589 60.2939C452.929 26.5687 405.224 26.5687 392.564 60.2938L235.532 478.617C225.961 504.116 244.809 531.323 272.044 531.323H586.109C613.344 531.323 632.193 504.116 622.621 478.617ZM474.951 56.7795C459.045 14.4069 399.108 14.4068 383.202 56.7794L226.17 475.103C214.144 507.139 237.825 541.323 272.044 541.323H586.109C620.328 541.323 644.009 507.139 631.983 475.103L474.951 56.7795Z"
          />
        </svg>
      </div>
      <div class="logo-s">
        <svg x="0px" y="0px" viewBox="0 0 664 566">
          <path
            fill="none"
            d="M351.767 234.767L514.139 91.1587C514.124 91.1422 514.109 91.1257 514.095 91.1091C512.16 88.9228 510.183 86.7784 508.167 84.6769C485.186 60.7304 456.984 42.3507 425.712 31.0022C391.674 18.6503 355.085 15.0217 319.285 20.4476C283.484 25.8735 249.613 40.1811 220.765 62.064C191.916 83.9469 169.009 112.708 154.134 145.721C139.26 178.734 132.892 214.947 135.613 251.054C138.333 287.161 150.056 322.011 169.709 352.422C189.363 382.834 216.321 407.837 248.123 425.15C277.342 441.057 309.793 450.006 342.955 451.355C345.887 451.474 348.825 451.534 351.767 451.534L351.767 234.767ZM342.955 442.535L342.955 230.797L501.56 90.5197C479.56 67.6728 452.594 50.1316 422.706 39.2854C390.052 27.4356 354.95 23.9544 320.605 29.1597C286.26 34.3651 253.766 48.0911 226.09 69.0844C198.414 90.0778 176.438 117.67 162.168 149.341C147.898 181.012 141.79 215.753 144.4 250.392C147.01 285.031 158.255 318.465 177.11 347.64C195.964 376.815 221.827 400.802 252.336 417.411C280.262 432.614 311.266 441.191 342.955 442.535Z"
          />
          <path
            fill="none"
            d="M311.233 330.814L148.861 474.422C148.876 474.439 148.891 474.455 148.905 474.472C150.84 476.658 152.817 478.802 154.833 480.904C177.814 504.85 206.016 523.23 237.288 534.579C271.326 546.93 307.915 550.559 343.715 545.133C379.516 539.707 413.387 525.4 442.235 503.517C471.084 481.634 493.991 452.873 508.866 419.86C523.74 386.847 530.108 350.634 527.387 314.527C524.667 278.42 512.944 243.57 493.291 213.158C473.637 182.747 446.679 157.743 414.877 140.43C385.658 124.524 353.207 115.575 320.045 114.226C317.113 114.107 314.175 114.047 311.233 114.047L311.233 330.814ZM320.045 123.045L320.045 334.784L161.44 475.061C183.44 497.908 210.406 515.449 240.294 526.295C272.948 538.145 308.05 541.626 342.395 536.421C376.74 531.216 409.234 517.49 436.91 496.496C464.586 475.503 486.562 447.911 500.832 416.24C515.102 384.569 521.21 349.828 518.6 315.189C515.99 280.55 504.745 247.116 485.89 217.941C467.036 188.766 441.173 164.779 410.664 148.17C382.738 132.967 351.734 124.389 320.045 123.045Z"
          />
        </svg>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoadingSpinner",
  mounted() {
    // console.log(this.$store.state.loading);
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style scoped>
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  isolation: isolate;
  z-index: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  overflow: hidden;
  user-select: none;
  cursor: default;
  transition: all 200ms;
  animation: bg-fade-in 5000ms ease;
  animation-fill-mode: forwards;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 400px;
  height: 280px; */
  position: relative;
  isolation: isolate;
  z-index: 1;
}

.logo-m {
  width: 140px;
  height: 140px;
  background: var(--primary);
  border-radius: 50%;
  padding: 1rem;
  object-fit: contain;
  outline: 1px solid var(--highlight);
  animation: pulse-blue 3s infinite, move-from-left 3s infinite linear,
    effect3d-m 3s infinite linear;
}

.logo-s {
  width: 140px;
  height: 140px;
  background: var(--gray2);
  border-radius: 50%;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  outline: 1px solid var(--gray5);
  animation: pulse-gray 3s infinite, move-from-right 3s infinite linear,
    effect3d-s 3s infinite linear;
}

.logo-m svg {
  stroke-width: 8px;
  stroke: #0e63d2;
  width: 100%;
  height: 100%;
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
  animation: main-animation 3s forwards infinite ease-out;
}

.logo-m svg path:nth-of-type(2) {
  animation: main-animation 3s forwards infinite ease-out !important;
  animation-delay: 120ms !important;
}

.logo-s svg {
  stroke-width: 8px;
  stroke: var(--gray6);
  width: 100%;
  height: 100%;
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
  animation: main-animation 3s forwards infinite;
}

.logo-s svg path:nth-of-type(2) {
  animation: main-animation 3s forwards infinite !important;
  animation-delay: 120ms !important;
}

@keyframes main-animation {
  0% {
    stroke-dashoffset: 0;
    /* stroke-dasharray: 1000; */
  }

  50% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes move-from-left {
  0% {
    transform: translateX(-10%);
  }

  50% {
    transform: translateX(110%);
  }

  100% {
    transform: translateX(-10%);
  }
}

@keyframes move-from-right {
  0% {
    transform: translateX(10%);
  }

  50% {
    transform: translateX(-110%);
  }

  100% {
    transform: translateX(10%);
  }
}

@keyframes effect3d-m {
  0% {
    z-index: 1;
  }

  25% {
    scale: 1.2;
  }

  75% {
    scale: 0.8;
  }
}

@keyframes effect3d-s {
  25% {
    scale: 0.8;
  }

  75% {
    scale: 1.2;
  }
}

@keyframes pulse-blue {
  0% {
    background: #172e69;
    box-shadow: var(--highlight) 0px 0px 20px;
  }

  50% {
    background: var(--primary);
    box-shadow: transparent 0px 0px 0px;
  }

  100% {
    background: #172e69;
    box-shadow: var(--highlight) 0px 0px 20px;
  }
}

@keyframes pulse-gray {
  0% {
    background: var(--gray2);
    box-shadow: var(--gray6) 0px 0px 20px;
  }

  50% {
    background: var(--gray1);
    box-shadow: transparent 0px 0px 0px;
  }

  100% {
    background: var(--gray2);
    box-shadow: var(--gray6) 0px 0px 20px;
  }
}
</style>
