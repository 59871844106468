import tempWidgetClient from "../../axios/tempWidgetClient";

export default async function getWeather(city) {
  try {
    const endpoint = `http://api.weatherapi.com/v1/current.json?key=0b42c4129bbf45758ff95143230901&q=${city}&aqi=yes`;
    const response = await tempWidgetClient.get(endpoint);

    // if (response.status === 500) throw new Error("Error fetching weather data");

    return {
      data: {
        description: response.data.current.condition.text,
        temperature: response.data.current.temp_c,
        icon: response.data.current.condition.icon,
      },
      status: response.status,
    };
  } catch (e) {
    console.log(e);

    return {
      data: {
        description: "Error fetching weather data",
      },
      status: {
        code: 500,
      },
    };
  }
}
