<template>
  <main class="dropdown-container" v-if="unitsList.length">
    <div class="dropbtn" :title="'Change Unit'">
      <p class="-xstb">{{ selectedUnit.unit_title }}</p>
      <div class="arrow-wrapper">
        <IconArrowDown strokeColor="var(--white1)" />
      </div>
    </div>
    <div class="dropdown-content">
      <p
        v-for="unit in unitsList"
        :key="unit"
        class="-xstr"
        @click="handleClick(unit)"
      >
        {{ unit.unit_title }}
      </p>
    </div>
  </main>
</template>

<script>
import { getProject } from "../../services/requests/get/projects";
import { mapActions } from "vuex";
import IconArrowDown from "../../assets/icons/dynamicIcons/IconArrowDown.vue";

export default {
  components: { IconArrowDown },
  data() {
    return {
      selectedUnit: null,
      unitsList: [],
    };
  },
  async created() {
    const res = await getProject(this.$store.state.project.proj_id);
    if (res.status !== 200) return;

    this.unitsList = res.data.units;
    this.setSelectedUnit();
  },
  methods: {
    ...mapActions({ updateCurrentUnit: "unitModule/updateCurrentUnit" }),
    setSelectedUnit() {
      //not optimized, this component should be set with the info that comes from the units list
      //sets the selected unit to the unit in unitsList that has the same id as the unit in the store
      this.selectedUnit = this.unitsList.find(
        (unit) => unit.id == this.$store.state.unitModule.unit.id
      );
    },
    handleClick(unit) {
      // this.$store.dispatch("unitModule/updateCurrentUnit", unit);
      if (this.selectedUnit.id === unit.id) return;
      this.updateCurrentUnit(unit);

      this.$router.go(0);
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 160px !important;
  height: 45px;
  border-radius: 2px;
  padding-inline: 16px;
  padding-block: 8px;
  /* background: var(--secondary); */
  /* background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    var(--secondary-highlight) 51%,
    var(--primary) 100%
  );
  color: var(--white1);
  background-size: 400% auto; */
  /* color: var(--secondary); */
  cursor: pointer;
  animation: slide-in-text 200ms ease-in-out;
  transition: all 500ms;
}
.dropdown-container:hover {
  background: rgba(255, 255, 255, 0.1);
}
.dropbtn {
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
.dropdown-content {
  display: none;
  width: inherit;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content p {
  color: black;
  padding-inline: 16px;
  padding-block: 12px;
  text-decoration: none;
  display: block;
  border-left: 4px solid var(--info);
  /* margin-block: 2px; */
}
.dropdown-content p:hover {
  background: rgba(202, 228, 255, 0.5);
}
.dropdown-container:hover .dropdown-content {
  display: block;
  top: 100%;
  left: 0;
  user-select: none;
  cursor: pointer;
  text-align: left;
}
/* .dropdown-container:hover {
  background-position: right center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
} */

.arrow-wrapper {
  transition: 150ms ease;
  transform: scale(0.8);
}

.dropdown-container:hover .arrow-wrapper {
  transform: rotateZ(180deg) scale(0.8);
}

@media screen and (max-width: 719px) {
  .dropdown-container {
    width: 256px !important;
  }
}
</style>
