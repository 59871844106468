import _ from "lodash";

const unitModule = {
  namespaced: true,
  state: {
    unit: null,
  },
  mutations: {
    unit(state, unit) {
      state.unit = unit;
    },
    updateCurrentUnit(state, unit) {
      console.log("updating...: ", state, unit);
      state.unit = _.cloneDeep(unit);
      console.log(state.unit);
    },
    setUnitCurrentStage(state, currentStage) {
      state.unit.stage_value = currentStage;
      console.log("UNIT STAGE SET SUCCESSFULLY: ", state.unit.stage_value);
    },
  },
  actions: {
    unit({ commit }, unit) {
      commit("unit", unit);
    },
    setUnitCurrentStage({ commit }, currentStage) {
      commit("setUnitCurrentStage", currentStage);
    },
    updateCurrentUnit({ commit }, unit) {
      commit("updateCurrentUnit", unit);
    },
  },
  getters: {
    unit: (state) => {
      return state.unit;
    },
  },
};

export default unitModule;
