import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";
import VCalendar from "v-calendar";
import { Calendar, DatePicker } from "v-calendar";
import getRemainingChars from "./plugins/getRemainingChars";
import "v-calendar/dist/style.css";
import "./assets/main.css";
import "./assets/icons.css";

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(VCalendar, {})
  .component("Calendar", Calendar)
  .component("DatePicker", DatePicker)
  .use(getRemainingChars)
  .mount("#app");
