import axios from "axios";

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => {
    console.log("PROJECT DATE RESPONSE", response);
    return response;
  },

  (error) => {
    console.log("PROJECT DATE ERROR", error);
    return error.response;
  }
);

export default apiClient;
